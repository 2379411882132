import React, { Component } from 'react';

const initData = {
    heading: "Our Valuable Clients",
    headingText_1: "A few of our clients, who believed in our capabilities and inspired us to give our best.",
    headingText_2: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laborum obcaecati.",
    image_1: "/img/case_studies_1.jpg",
    image_2: "/img/case_studies_2.jpg",
    image_3: "/img/case_studies_3.jpg",
    image_4: "/img/case_studies_4.jpg",
    image_5: "/img/case_studies_5.jpg",
    clients: "/img/clients.avif",
    title_1: "Digital Marketing",
    title_2: "App Development",
    title_3: "Content Management",
    title_4: "Data Analysis",
    title_5: "SEO Marketing",
    title_6: "Marketing Strategy",
    text: "Lorem ipsum dolor sit amet, consectet ur adipisicing elit.",
    btnText: "View More"
}

class PortfolioOne extends Component {
    state = {
        initData: {}
    }
    baseUrl = 'https://ritswa-consultancy-static-assets.s3.ap-south-1.amazonaws.com';
    componentDidMount(){
        this.setState({
            initData: initData
        })
    }
    render() {
        return (
            <section id="portfolio" className="portfolio-area overflow-hidden bg-grey ptb_100">
                <div className="shape shape-top">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 100" preserveAspectRatio="none" fill="#FFFFFF">
                    <path className="shape-fill" d="M421.9,6.5c22.6-2.5,51.5,0.4,75.5,5.3c23.6,4.9,70.9,23.5,100.5,35.7c75.8,32.2,133.7,44.5,192.6,49.7
                        c23.6,2.1,48.7,3.5,103.4-2.5c54.7-6,106.2-25.6,106.2-25.6V0H0v30.3c0,0,72,32.6,158.4,30.5c39.2-0.7,92.8-6.7,134-22.4
                        c21.2-8.1,52.2-18.2,79.7-24.2C399.3,7.9,411.6,7.5,421.9,6.5z" />
                    </svg>
                </div> 
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-10 col-lg-7">
                            {/* Section Heading */}
                            <div className="section-heading text-center">
                                <h2>{this.state.initData.heading}</h2>
                                <p className="d-none d-sm-block mt-4">{this.state.initData.headingText_1}</p>
                                <p className="d-block d-sm-none mt-4">{this.state.initData.headingText_1}</p>
                            </div>
                        </div>
                    </div>
            
                    {/* Portfolio Items */}
                    <div className="row items portfolio-items">
                        <img className="clientImage" src={this.baseUrl+this.state.initData.clients} alt="Our Valuable Clients"></img>
                    </div>
                </div>
                <div className="shape shape-bottom">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 100" preserveAspectRatio="none" fill="#FFFFFF">
                    <path className="shape-fill" d="M421.9,6.5c22.6-2.5,51.5,0.4,75.5,5.3c23.6,4.9,70.9,23.5,100.5,35.7c75.8,32.2,133.7,44.5,192.6,49.7
                c23.6,2.1,48.7,3.5,103.4-2.5c54.7-6,106.2-25.6,106.2-25.6V0H0v30.3c0,0,72,32.6,158.4,30.5c39.2-0.7,92.8-6.7,134-22.4
                c21.2-8.1,52.2-18.2,79.7-24.2C399.3,7.9,411.6,7.5,421.9,6.5z" />
                    </svg>
                </div>
            </section>
        );
    }
}

export default PortfolioOne;