import React, { Component } from 'react';

import ScrollupSection from '../components/Scrollup/Scrollup';
import Header from '../components/Header/HeaderThree';
import HeroSection from '../components/Hero/HeroOne';
import ContentSection from '../components/Content/ContentOne';
import ServiceSection from '../components/Services/ServiceOne';
import PortfolioSection from '../components/Portfolio/PortfolioOne';
import ReviewSection from '../components/Reviews/ReviewOne';
import ContactSection from '../components/Contact/ContactOne';
import FooterSection from '../components/Footer/FooterOne';
import Technologies from '../components/Technologies/Technologies';


class ThemeSeven extends Component {
    baseUrl = 'https://ritswa-consultancy-static-assets.s3.ap-south-1.amazonaws.com';
    render() {
        return (
            <div>
                <ScrollupSection />
                <div className="main overflow-hidden">
                    <Header imageData={this.baseUrl + "/img/logo-black.png"} />
                    <HeroSection />
                    {/* <PromoSection /> */}
                    <ServiceSection />
                    <ContentSection />
                    {/* <ContentSectionTwo /> */}
                    <Technologies />
                    <PortfolioSection />
                    {/* <PricingSection /> */}
                    <ReviewSection />
                    <ContactSection />
                   
                    <FooterSection />
                </div>
            </div>
        );
    }
}

export default ThemeSeven;