import React, { Component } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Axios from 'axios';


const data = {
    "heading": "Let's connect!",
    "headingText_1": "If you have any queries, feel free to contact us. We will try to serve you at the topmost level.",
    "headingText_2": "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laborum obcaecati.",
    "btnText": "Send Message",
    "contactData": [
      {
        "id": 1,
        "listClass": "contact-info color-1 bg-hover active hover-bottom text-center p-5 m-3",
        "listClass_1": "contact-info color-1 bg-hover active hover-bottom dark-shadow text-center p-5 m-3",
        "iconClass": "fas fa-mobile-alt fa-3x",
        "linkClass": "d-block my-2",
        "title": "+91 98641 60012 ",
        "href":"tel:+919864160012",
        "text": "You can directly contact us through our number, our employees are always excited to help you."
      },
      {
        "id": 2,
        "listClass": "contact-info color-3 bg-hover active hover-bottom text-center p-5 m-3",
        "listClass_1": "contact-info color-3 bg-hover active hover-bottom dark-shadow text-center p-5 m-3",
        "iconClass": "fas fa-envelope-open-text fa-3x",
        "linkClass": "d-block my-2",
        "title": "hello@ritswa.com",
        "href": "mailto:hello@ritswa.com?Subject=My%20Query",
        "text": "If you have any queries or suggestions for us, please mail us."
      }
    ]
  }

 function LoadingButtonContent(props){
    return ( <span className="text-white ">Processing...</span>)
} 
function NormalButtonContent(){
    return (<span className="text-white "><i className="fas fa-paper-plane pr-3" />Send Message</span>)
}
class ContactOne extends Component {
    state = {
      
        //the display property on state allows me to toggle the view of the form vs. the view of the user's data
        fullName: '', 
        businessName: '', 
        phone: 0, 
        email: '',
        message: '',
        data: data,
        contactData: data.contactData ,
        isLoading : false,
        displaySuccess: false
      };
    
    // componentDidMount(){
    //     axios.get(`${BASE_URL}`)
    //         .then(res => {
    //             this.setState({
    //                 data: res.data,
    //                 contactData: res.data.contactData
    //             })
    //             // console.log(this.state.data)
    //         })
    //     .catch(err => console.log(err))
    // }
    inputCheck = (e) => {
        let filter = e.target.getAttribute('filter')   
        e.target.value = e.target.value.replace(new RegExp(filter, 'g'), '')   
        this.setState({[e.target.name]: e.target.value})
        
      }
       
      submitCheck = () => {
     
        if(!this.state.fullName){
          toast.error('Name cannot be empty', { position:"top-right", autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined });
        } else if(this.state.phone.length < 10 || !this.state.phone){
          toast.error('Invalid Phone number', { position:"top-right", autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined });
        } else if (!this.state.email.match(/@./g)) {
          toast.error('Invalid email id', { position:"top-right", autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined });
        } else {
            this.setState({ isLoading: true })
            let data = {"email":this.state.email,"name":this.state.fullName,"phone":this.state.phone,"message":this.state.message,"businessName":this.state.businessName}

            Axios.post('https://pepucrq0v4.execute-api.ap-south-1.amazonaws.com/form/contact',data).then(()=>{
                this.setState({ isLoading: false,displaySuccess:true })
                toast.success('Message Sent!', { position:"top-right", autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined });
            }).catch((err)=>{
                this.setState({ isLoading: false })
                toast.error('Some error occured! Please call us on  +91 98641 60012', { position:"top-right", autoClose: 10000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined });
            })
        }
     }
  
   
    render() {
        let buttonContent
        if(this.state.isLoading){
            buttonContent = <LoadingButtonContent />
        }else{
            buttonContent = <NormalButtonContent />
        }
        return (
            <section id="contact" className="contact-area bg-grey ptb_100">
                <div className="container">
                    <div className="row justify-content-between align-items-center">
                        <div className="col-12 col-lg-5">
                            {/* Section Heading */}
                            <div className="section-heading text-center mb-3">
                                <h2>{this.state.data.heading}</h2>
                                <p className="d-none d-sm-block mt-4">{this.state.data.headingText_1}</p>
                                <p className="d-block d-sm-none mt-4">{this.state.data.headingText_1}</p>
                            </div>
                            {/* Contact Us */}
                            <div className="contact-us">
                                <ul>
                                    {/* Contact Info */}
                                    {this.state.contactData.map((item, idx) => {
                                        return(
                                            <li key={`co_${idx}`} className={item.listClass}>
                                                <span><i className={item.iconClass} /></span>
                                                <a className={item.linkClass} href={item.href}>
                                                    <h3>{item.title}</h3>
                                                </a>
                                                <p>{item.text}</p>
                                            </li>
                                        );
                                    })}
                                </ul>
                            </div>
                        </div>
                        <div className="col-12 col-lg-6 pt-4 pt-lg-0">
                            {/* Contact Box */}
                            <div className="contact-box text-center">
                                {/* Contact Form */}
                                {this.state.displaySuccess ? (
                                  <div>
                                   
                                   <div class="checkmark-container">
                                   <div class="checkmark">
                                       <svg class="star" height="19" viewBox="0 0 19 19" width="19" xmlns="http://www.w3.org/2000/svg">
                                           <path d="M8.296.747c.532-.972 1.393-.973 1.925 0l2.665 4.872 4.876 2.66c.974.532.975 1.393 0 1.926l-4.875 2.666-2.664 4.876c-.53.972-1.39.973-1.924 0l-2.664-4.876L.76 10.206c-.972-.532-.973-1.393 0-1.925l4.872-2.66L8.296.746z" >
                                           </path></svg>
                                       <svg class="star" height="19" viewBox="0 0 19 19" width="19" xmlns="http://www.w3.org/2000/svg">
                                           <path d="M8.296.747c.532-.972 1.393-.973 1.925 0l2.665 4.872 4.876 2.66c.974.532.975 1.393 0 1.926l-4.875 2.666-2.664 4.876c-.53.972-1.39.973-1.924 0l-2.664-4.876L.76 10.206c-.972-.532-.973-1.393 0-1.925l4.872-2.66L8.296.746z" >
                                           </path></svg>
                                       <svg class="star" height="19" viewBox="0 0 19 19" width="19" xmlns="http://www.w3.org/2000/svg">
                                           <path d="M8.296.747c.532-.972 1.393-.973 1.925 0l2.665 4.872 4.876 2.66c.974.532.975 1.393 0 1.926l-4.875 2.666-2.664 4.876c-.53.972-1.39.973-1.924 0l-2.664-4.876L.76 10.206c-.972-.532-.973-1.393 0-1.925l4.872-2.66L8.296.746z" >
                                           </path></svg>
                                       <svg class="star" height="19" viewBox="0 0 19 19" width="19" xmlns="http://www.w3.org/2000/svg">
                                           <path d="M8.296.747c.532-.972 1.393-.973 1.925 0l2.665 4.872 4.876 2.66c.974.532.975 1.393 0 1.926l-4.875 2.666-2.664 4.876c-.53.972-1.39.973-1.924 0l-2.664-4.876L.76 10.206c-.972-.532-.973-1.393 0-1.925l4.872-2.66L8.296.746z" >
                                           </path></svg>
                                       <svg class="star" height="19" viewBox="0 0 19 19" width="19" xmlns="http://www.w3.org/2000/svg">
                                           <path d="M8.296.747c.532-.972 1.393-.973 1.925 0l2.665 4.872 4.876 2.66c.974.532.975 1.393 0 1.926l-4.875 2.666-2.664 4.876c-.53.972-1.39.973-1.924 0l-2.664-4.876L.76 10.206c-.972-.532-.973-1.393 0-1.925l4.872-2.66L8.296.746z" >
                                           </path></svg>
                                       <svg class="star" height="19" viewBox="0 0 19 19" width="19" xmlns="http://www.w3.org/2000/svg">
                                           <path d="M8.296.747c.532-.972 1.393-.973 1.925 0l2.665 4.872 4.876 2.66c.974.532.975 1.393 0 1.926l-4.875 2.666-2.664 4.876c-.53.972-1.39.973-1.924 0l-2.664-4.876L.76 10.206c-.972-.532-.973-1.393 0-1.925l4.872-2.66L8.296.746z" >
                                           </path></svg>
                                       <svg class="checkmark__check" height="36" viewBox="0 0 48 36" width="48" xmlns="http://www.w3.org/2000/svg">
                                           <path d="M47.248 3.9L43.906.667a2.428 2.428 0 0 0-3.344 0l-23.63 23.09-9.554-9.338a2.432 2.432 0 0 0-3.345 0L.692 17.654a2.236 2.236 0 0 0 .002 3.233l14.567 14.175c.926.894 2.42.894 3.342.01L47.248 7.128c.922-.89.922-2.34 0-3.23">
                                           </path></svg>
                                       <svg class="checkmark__background" height="115" viewBox="0 0 120 115" width="120" xmlns="http://www.w3.org/2000/svg">
                                           <path d="M107.332 72.938c-1.798 5.557 4.564 15.334 1.21 19.96-3.387 4.674-14.646 1.605-19.298 5.003-4.61 3.368-5.163 15.074-10.695 16.878-5.344 1.743-12.628-7.35-18.545-7.35-5.922 0-13.206 9.088-18.543 7.345-5.538-1.804-6.09-13.515-10.696-16.877-4.657-3.398-15.91-.334-19.297-5.002-3.356-4.627 3.006-14.404 1.208-19.962C10.93 67.576 0 63.442 0 57.5c0-5.943 10.93-10.076 12.668-15.438 1.798-5.557-4.564-15.334-1.21-19.96 3.387-4.674 14.646-1.605 19.298-5.003C35.366 13.73 35.92 2.025 41.45.22c5.344-1.743 12.628 7.35 18.545 7.35 5.922 0 13.206-9.088 18.543-7.345 5.538 1.804 6.09 13.515 10.696 16.877 4.657 3.398 15.91.334 19.297 5.002 3.356 4.627-3.006 14.404-1.208 19.962C109.07 47.424 120 51.562 120 57.5c0 5.943-10.93 10.076-12.668 15.438z" >
                                           </path></svg>
                                   </div>
                                   
                                   </div>
                                   <div class="mt-5 thankYou">Thank you for reaching out. We will contact you shortly</div>
                                   </div>
                                   
                                  
                                ):(
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="form-group">
                                                <input filter="[^a-zA-Z ]" type="text" className="form-control" name="fullName" placeholder="Name" required="required" onChange={this.inputCheck}/>
                                            </div>
                                            <div className="form-group">
                                                <input filter="[^a-zA-Z ]" type="text" className="form-control" name="businessName" placeholder="Company Name" required="required" onChange={this.inputCheck} />
                                            </div>
                                            <div className="form-group">
                                                <input type="email" className="form-control" name="email" placeholder="Email" required="required"  onChange={(e) => {this.setState({email:e.target.value})}}/>
                                            </div>
                                            <div className="form-group">
                                                <input  type="text" className="form-control" name="phone" placeholder="Phone" required="required" onChange={this.inputCheck}/>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form-group">
                                                <textarea className="form-control" name="message" placeholder="Message" required="required" defaultValue={""}  onChange={this.inputCheck}/>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <button onClick={this.submitCheck} disabled={this.state.isLoading} className="btn btn-bordered active btn-block mt-3">{buttonContent}</button>
                                        </div>
                                    </div>

                                )}
                                    
                                    <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover/><ToastContainer />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            
        );
    }
}

export default ContactOne;